import React, { useEffect, useState } from 'react'
import { LayoutContext } from '../layout'
// import NewsList from '../components/newsList'
import MyNewsDeskList from '../components/newsListMyNewsDesk'
import Hero from '../components/hero'
import Gauge from '../components/gauge'
import Teasers from '../components/teasers'
import ContentWrapper from '../components/contentWrapper'
import ArticleTeasers from '../components/articleTeasers'
import ArticleTeaserLarge from '../components/articleTeaserLarge'
import Seo from '../components/seo'
import '../styles/patterns/metrics.scss'

const Startpage = ({ doc, hero, news, myNewsDesk, articles, teasers }) => {
  const [isMobile, setIsMobile] = useState(false)
  const { localization, langSlug } = React.useContext(LayoutContext)

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true)
    }
    matchMedia(`screen and (max-width:768px)`).addListener(function(mql) {
      setIsMobile(mql.matches)
    })
  }, [])

  return (
    <div>
      <Seo
        title={doc.metaTitle}
        description={doc.metaDescription}
        ogType="website"
        url=""
      />
      <Hero
        title={hero.heading}
        link={hero.link}
        linkText={hero.linkText}
        text={hero.text}
        image={hero.image.fluid}
      />
      <main>
        <ContentWrapper modifier="md light-bg">
          <div className="gauge">
            <Gauge title={doc.productionTitle} text={doc.productionText} />
          </div>
        </ContentWrapper>
        <ContentWrapper modifier="md alt-bg">
          <div className="metrics">
            <h3 className="metrics__header">{localization.metrics_header}</h3>
            <div className="metrics__group">
              <div className="metrics__item">
                <span className="metrics__item__value">
                  10,8 <span>TWh</span>
                </span>{' '}
                <span className="metrics__item__key">
                  {localization.metrics_produced}
                </span>
              </div>
              <div className="metrics__item">
                <span className="metrics__item__value">
                  89,8<span>%</span>
                </span>{' '}
                <span className="metrics__item__key">
                  {localization.metrics_availability}
                </span>
              </div>
              <div className="metrics__item">
                <span className="metrics__item__value">
                  7<span>%</span>
                </span>{' '}
                <span className="metrics__item__key">
                  {localization.metrics_usage}
                </span>
              </div>
            </div>
          </div>
        </ContentWrapper>
        <ContentWrapper modifier="wide">
          {/* <NewsList
            title={localization.news}
            items={news}
            link={`${langSlug}/${localization.newsSlug}`}
          /> */}
          <MyNewsDeskList
            title={localization.myNewsDesk}
            items={myNewsDesk}
            link={`${localization.myNewsDeskSlug}`}
          />
          {articles.length > 0 && (
            <div className="relative">
              <h2 className="heading-2 heading-2--blue">Kärnpunkten</h2>
              {!isMobile && (
                <ArticleTeaserLarge
                  title={articles[0].heading}
                  link={articles[0].link}
                  image={articles[0].image}
                  text={articles[0].text}
                  published={articles[0].published}
                  category={articles[0].category}
                  secondaryLink={`${langSlug}/${localization.blog_slug}`}
                  secondaryLinkText={localization.show_blog}
                />
              )}
              <ArticleTeasers
                useSlider={true}
                articles={isMobile ? articles : articles.slice(1)}
              />
            </div>
          )}
          <Teasers teasers={teasers} />
        </ContentWrapper>
      </main>
    </div>
  )
}

export default Startpage
