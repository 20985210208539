import React from 'react'
import { LayoutContext } from '../../layout'
import { TiArrowRight } from 'react-icons/ti'
import './newsList.scss'
import ButtonLink from '../buttons/buttonLink'

function MyNewsDeskList({ title, items, link }) {
  const { localization } = React.useContext(LayoutContext)

  if (items.length === 0) return null

  const listItems = items.map((item, index) => {
    return (
      <li key={index}>
        <a href={item.link}>
          <h3 className="heading-3">
            <span>{item.heading}</span>
          </h3>
          <p className="newslist__date meta">{item.published}</p>
          <p>
            <span className="tag">
              {item.type === 'pressrelease'
                ? localization.press_tag
                : localization.news_tag}
            </span>
            {item.listText}
          </p>
        </a>
      </li>
    )
  })

  return (
    <div className="news-list">
      {title && (
        <h2 className="news-list__heading heading-2 heading-2--blue">
          {title}
        </h2>
      )}
      <ul className="news-list__items">{listItems}</ul>
      <div className="flex-right">
        <a
          className="news-list__all arrow-link"
          href={link}
          aria-label={localization.show_all_news}
        >
          {localization.show_all}
          <TiArrowRight />
        </a>
        <ButtonLink href={localization.myNewsDeskFollowSlug}>
          {localization.myNewsDeskFollow}
        </ButtonLink>
      </div>
    </div>
  )
}

export default MyNewsDeskList
